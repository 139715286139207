<template>
  <v-app>
    <v-app-bar app class="navbar-toolbar">
      <v-app-bar-nav-icon @click="sidebar = !sidebar" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <div class="navbar-logo-container">
        <a href="/">
          <img src="../assets/alhambradote.png" class="navbar-logo">
        </a>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down ml-auto">
        <v-btn
            flat
            v-for="item in filteredMenuItems"
            :key="item.title"
            :to="item.path"
            @click="item.title === ''"
        >
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-menu v-if="isLoggedIn" rounded>
        <template v-slot:activator="{ props }">
          <v-btn class="text-none" stacked v-bind="props">
            <v-badge color="success" dot class="custom-badge" overlap>
              <v-avatar color="white">
                <img :src="user.initials" alt="user-login" />
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider></v-divider>
              <v-list>
                <!-- Dialog Notifications -->
                <v-dialog v-model="showNotifications" max-width="500">
                  <v-card>
                    <v-card-title>
                      <span class="font-weight-bold text--primary--text font-italic d-flex justify-center">Notifications</span>
                    </v-card-title>
                    <v-card-text>
                      <v-list>
                        <v-list-item v-for="(notification, index) in displayNotifs" :key="index">
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">{{ notification.commission }}</v-list-item-title>
                            <v-list-item-subtitle>{{ notification.senderName }}</v-list-item-subtitle>
                            <v-list-item-title>{{ notification.content }}</v-list-item-title>
                            <v-list-item-subtitle>{{ notification.date }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!displayNotifs.length">
                          <v-list-item-content>
                            <v-list-item-title>Pas de nouvelles notifications</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="showNotifications = false">Fermer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showManageNotifications" max-width="500">
                  <v-card>
                    <v-card-title>
                      <span class="font-weight-bold text--primary--text font-italic d-flex justify-center">Gérer les Notifications</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form>
                        <v-checkbox
                            v-for="(notification, index) in notifs"
                            :key="index"
                            v-model="selectedNotifications"
                            :label="notification.commission"
                            :value="notification.commission"
                            :disabled="notification.isDisabled"
                        ></v-checkbox>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="applyNotificationSettings">Appliquer</v-btn>
                      <v-btn color="grey" @click="showManageNotifications = false">Annuler</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-list-item v-if="userRole !== 'ROLE_ADMIN'" @click.stop="showNotifications = true">
                  <v-badge color="error" :content="displayNotifs.length">
                    <v-icon>mdi-bell-outline</v-icon>
                  </v-badge>
                  <v-list-item-content>
                    <v-list-item-title>Notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="userRole !== 'ROLE_ADMIN'"></v-divider>

                <v-list-item v-if="userRole !== 'ROLE_ADMIN'" @click.stop="showManageNotifications = true">
                  <v-icon>mdi-cog-transfer</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>Gérer les notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="userRole !== 'ROLE_ADMIN'"></v-divider>
                <v-list-item @click="logoutAuth">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Déconnexion</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        v-model="sidebar"
        app
        temporary
        class="hidden-md-and-up"
    >
      <v-list>
        <v-list-item
            v-for="item in filteredMenuItems"
            :key="item.title"
            :to="item.path"
            @click="item.title === ''"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { logout } from '@/services/auth';
import { checkAuthentication } from "@/LoginCheck";
import {displayedNotifications, notifications} from "@/components/notifications";
import axios from "axios";
import urls from "@/config/config";

export default {
  name: "NavBar",
  data() {
    return {
      sidebar: false,
      isLoggedIn: false,
      userRole: '',
      showNotifications: false,
      showManageNotifications: false,
      accessToken: localStorage.getItem('access'),
      refreshToken: localStorage.getItem('refresh'),
      checkInterval: null,
      user: {
        initials: '/img/connection.png',
        fullName: '',
        email: '',
      },
      menuItems: [
        { title: 'Connexion', path: '/login', icon: 'mdi-login' },
        { title: 'Inscription', path: '/register', icon: 'mdi-account-plus' },
        { title: '' }
      ],
      notifs: notifications,
      displayNotifs:displayedNotifications,
      selectedNotifications: [],
    };
  },
  watch: {
    selectedNotifications() {
      localStorage.setItem('selectedNotifications', this.selectedNotifications);
    },
  },
  methods: {
    async logoutAuth() {
      this.isLoggedIn = false;
      logout();
    },
    async refreshAccessToken() {
      try {
        const response = await axios.post(urls.refresh_token_request, {
          refresh_token: this.refreshToken
        });
        localStorage.setItem('access', response.data.access_token);
        this.accessToken = response.data.access_token;
      } catch (error) {
        console.error(error);
      }
    },
    isTokenNearExpiry() {
      if (!this.accessToken) {
        return true;
      }

      const decodedToken = this.parseJwt(this.accessToken);
      if (!decodedToken || !decodedToken.exp) {
        return true;
      }

      const expirationDate = new Date(decodedToken.exp * 1000);
      const currentDate = new Date();
      const timeLeftInSeconds = (expirationDate - currentDate) / 1000;

      return timeLeftInSeconds <= 10;
    },
    parseJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = atob(base64Url.replace(/-/g, '+').replace(/_/g, '/'));
        return JSON.parse(decodeURIComponent(escape(base64)));
      } catch (e) {
        console.error('Erreur lors du décodage du token:', e);
        return null;
      }
    },
    fetchUserRole() {
      axios.post('http://localhost:8000/api/get_user_role', {
        user_email:this.user.email
      })
          .then(response => {
            // Récupérez le rôle de l'utilisateur à partir de la réponse
            this.userRole = response.data.role;
            console.log('Rôle de l\'utilisateur récupéré:', this.userRole);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération du rôle de l\'utilisateur:', error);
          });
    },
    startTokenCheck() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }

      this.checkInterval = setInterval(() => {
        if (this.isTokenNearExpiry()) {
          this.refreshAccessToken();
        }
      }, 5000);
    },
    applyNotificationSettings() {
      this.showManageNotifications = false;
    }
  },
  async created() {
    try {
      const response = await checkAuthentication();
      this.isLoggedIn = true;
      this.user.fullName = response.loginUser;
      this.user.email = response.email;
      localStorage.setItem('selectedNotifications', this.selectedNotifications);
    } catch (error) {
      console.log("Erreur lors de l'authentification:", error);
    }
    if (this.isLoggedIn) {
      this.fetchUserRole();
      this.startTokenCheck();
    }
  },
  computed: {
    filteredMenuItems() {
      return this.menuItems.filter(item => {
        if (this.isLoggedIn) {
          return item.title === '';
        } else {
          return item.title !== '';
        }
      });
    }
  },
  beforeUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>

<style scoped>
.navbar-toolbar {
  background: linear-gradient(to right, rgb(84, 51, 255), rgb(32, 189, 255), rgb(165, 254, 203)) !important;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
}

.navbar-logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}

.text--primary--text {
  color: cyan !important;
}
.font-italic {
  font-style: italic !important;
}

.navbar-logo {
  max-width: 100%;
  height: auto;
  max-height: 50px;
}

@media (max-width: 600px) {
  .navbar-logo {
    max-height: 60px;
  }

  .navbar-logo-container {
    bottom: 5px;
  }
}

@media (max-width: 400px) {
  .navbar-logo {
    max-height: 60px;
  }

  .navbar-logo-container {
    bottom: 0;
  }
}

.custom-badge .v-badge__badge {
  top: -4px; /* Ajuster la position verticale du badge */
}
</style>
