<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <!-- Menu de sélection pour ajouter une commission -->
      <v-col class="text-center mb-4 d-flex justify-center">
        <v-menu offset-y activator="parent">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">
              <v-icon color="white" left>mdi-account-multiple-plus-outline</v-icon>
              Choisir une commission
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="showDialog = true" class="py-1 text-center justify-center">
              <v-list-item-title>Ajouter une commission</v-list-item-title>
            </v-list-item>
            <v-list-item @click="temporaryCommission = true" class="py-1 text-center justify-center">
              <v-list-item-title>Ajouter une commission temporaire</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <!-- Formulaire pour ajouter une commission permanente -->
      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addCommission">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  v-model="commissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="selectedImageCommiteeId"
                  class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="message"
                  :type="isCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Formulaire pour ajouter une commission temporaire -->
      <v-dialog v-model="temporaryCommission" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTemporaryCommission">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  v-model="temporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>
              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="selectedImageTemporaryId"
                  class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
                  v-model="startDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="startTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>
              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="temporaryCommission = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Formulaire pour modifier une commission -->
    <v-dialog v-model="showEditDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Modifier une Commission</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateCommission">
            <v-text-field
                label="Nom Commission"
                v-model="editedCommissionName"
                prepend-inner-icon="mdi-account-group"
                variant="outlined"
                density="compact"
                required
            ></v-text-field>
            <v-select
                :items="defaultImages"
                item-title="title"
                item-value="id"
                v-model="editedImageCommiteeId"
                class="custom-select"
            >
              <template #item="{ item, props }">
                <v-list-item v-bind="props" class="custom-list-item">
                  <template #title>
                    <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                      <span class="custom-item-text">{{ item.title }}</span>
                    </div>
                  </template>
                </v-list-item>
              </template>
            </v-select>
            <v-btn block class="mb-8" color="blue" size="large" variant="tonal" type="submit">
              Modifier
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showEditDialog = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Pop-up de confirmation pour la suppression -->
    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirmation de suppression</v-card-title>
        <v-card-text>
          Êtes-vous sûr de vouloir supprimer cette commission ?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="confirmDeleteCommitee">Supprimer</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDeleteDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Affichage des commissions créées -->
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto">
          <v-card-title class="text-center">
            <span>Commissions</span>
          </v-card-title>
          <v-list>
            <v-list-item
                v-for="item in createdCommissions"
                :key="item.id"
                class="d-flex align-center justify-between"
            >
              <v-list-item-avatar>
                <v-img class="text-center" :src="item.prependAvatar" width="50" height="50"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-center">{{ item.title }}</v-list-item-title>
              <div class="text-center">
                <v-btn icon @click="editCommission(item)">
                  <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="showDeleteConfirmCommitee(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-dialog v-model="showEditTemporaryDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Modifier une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="updateTemporaryCommission">
              <v-text-field
                  label="Nom Commission"
                  v-model="editedTemporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="editedImageTemporaryId"
                  class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>

              <v-text-field
                  v-model="editedStartDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedStartTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedEndDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedEndTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Modifier
              </v-btn>

              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>

              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionUpdated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showEditTemporaryDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDeleteTempCommiteeDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Confirmation de suppression</v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer cette commission temporaire ?
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="confirmDeleteTempCommitee">Supprimer</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDeleteTempCommiteeDialog = false">Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-col cols="12" sm="6">
        <v-card class="mx-auto">
          <v-card-title class="text-center">
            Commissions Temporaires
          </v-card-title>
          <v-list>
            <v-list-item
                v-for="item in tempCommissions"
                :key="item.id"
                class="d-flex align-center justify-between"
            >
              <v-list-item-avatar>
                <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-center flex-grow-1">{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>
                Début : {{ getSplittedDate(item.startDate.date) }} {{ getSplittedTime(item.startDate.date) }} - Fin : {{ getSplittedDate(item.endDate.date) }} {{ getSplittedTime(item.endDate.date) }}
              </v-list-item-subtitle>
              <v-btn icon @click="openEditTempCommiteeDialog(item)">
                <v-icon color="blue">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="openDeleteTempCommiteeDialog(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {checkAuthentication} from "@/LoginCheck";
import axios from 'axios';
import urls from "@/config/config";


// Liste des images par défaut
const defaultImages = ref([
  { id: 1, prependAvatar: '/img/animation.png', title: 'spectacle' },
  { id: 2, prependAvatar: '/img/communication.png', title: 'partage' },
  { id: 3, prependAvatar: '/img/finance.png', title: 'finance' },
  { id: 4, prependAvatar: '/img/restauration.png', title: 'restauration' },
  { id: 5, prependAvatar: '/img/global-communication.png', title: 'monde' },
]);

const showDialog = ref(false);
const deleteTempCommitee = ref(null)
const deleteCommitee = ref(null)
const temporaryCommission = ref(false);
const commissionName = ref('');
const temporaryCommissionName = ref('');
const selectedImageCommiteeId = ref(null);
const selectedImageTemporaryId = ref(null);
const isTemporaryCommissionUpdated = ref(false);
const confirmDeleteTempCommiteeDialog = ref(false);
const createdCommissions = ref([]);
const tempCommissions = ref([]);
const startDate = ref('');
const startTime = ref('');
const endDate = ref('');
const endTime = ref('');
const message = ref('');
const showEditDialog = ref(false);
const confirmDeleteDialog = ref(false);
const editedCommission = ref(null);
const editedCommissionName = ref('');
const editedImageCommiteeId = ref('');
const temporaryCommiteeId = ref(null);
const showEditTemporaryDialog = ref(false);
const editedTemporaryCommissionName = ref('');
const editedImageTemporaryId = ref('');
const editedStartDate = ref('');
const editedStartTime = ref('');
const editedEndDate = ref('');
const editedEndTime = ref('');
const currentEmail = ref('');
const isCommissionCreated = ref(false);
const isTemporaryCommissionCreated = ref(false);
const isErrorDateMessage = ref(false);
const errorDateMessage = ref('');

// Chargement des commissions existantes lors du montage du composant
onMounted(async () => {
  fetchCommitees();
});


// Mettre à jour une commission temporaire
const updateTemporaryCommission = () => {
  const payload = {
    title: editedTemporaryCommissionName.value,
    prependAvatar: getPrependAvatarById(editedImageTemporaryId.value),
    startDate: editedStartDate.value,
    startTime: editedStartTime.value,
    endTime:editedEndTime.value,
    endDate: editedEndDate.value,
    user_email:currentEmail.value,
  };

  axios.put(urls.getApiUpdateTemporaryCommitee(temporaryCommiteeId.value), payload)
      .then(response => {
        console.log('Temporary committee updated successfully:', response.data);
        isTemporaryCommissionUpdated.value = true;
        showEditTemporaryDialog.value = false;
        fetchCommitees();
      })
      .catch(error => {
        console.error('Error updating temporary committee:', error);
        // Gérer l'erreur, par exemple afficher un message d'erreur
      });
};

const getSplittedDate = (date) => {
  return date.split(' ')[0];
};

const getSplittedTime = (date) => {
  const timePart = date.split(' ')[1];
  return timePart.split('.')[0];
};

const editCommission = (commission) => {
  editedCommission.value = commission;
  editedCommissionName.value = commission.title;
  editedImageCommiteeId.value = commission.prependAvatar;
  showEditDialog.value = true;
};

const updateCommission = async () => {
  const updatedCommission = {
    id: editedCommission.value.id,
    title: editedCommissionName.value,
    prependAvatar: getPrependAvatarById(editedImageCommiteeId.value),
    user_email:currentEmail.value,
  };

  try {
    const response = await axios.put(
        urls.getApiUpdateCommitee(updatedCommission.id), // Assuming this is the API endpoint function
        updatedCommission
    );

    if (response.data.success) {
      console.log("Commission updated successfully");
      fetchCommitees(); // Refresh the data after successful update
    } else {
      console.log("Failed to update commission");
      message.value = 'Erreur lors de la mise à jour de la commission.';
    }
  } catch (error) {
    console.log("Error updating commission:", error);
    message.value = 'Erreur lors de la mise à jour de la commission.';
  }
  showEditDialog.value = false;
};

const showDeleteConfirmCommitee = (commission) => {
  deleteCommitee.value = commission;
  confirmDeleteDialog.value = true;
};

const confirmDeleteTempCommitee = async () => {
  console.log("in temp data before delete", deleteTempCommitee.value)
  console.log("in temp remove", deleteTempCommitee.value.id)
  await axios.delete(urls.getApiDeleteTemporaryCommitee(deleteTempCommitee.value.id), {
    params: { user_email: currentEmail.value }
  })
      .then(response => {
        if (response.data.success) {
          console.log("delete completed")
          confirmDeleteTempCommiteeDialog.value = false;
          fetchCommitees();
        }
      })
      .catch(error => {
        console.log(error)
        message.value = 'Erreur lors de la suppression de la commission.';
      });
};

const confirmDeleteCommitee = async () => {
  console.log("commitee data before delete", deleteCommitee.value)
  console.log("commitee id",deleteCommitee.value.id)
  await axios.delete(urls.getApiDeleteCommitee(deleteCommitee.value.id),{
    params: { user_email: currentEmail.value }
  })
      .then(response => {
        if (response.data.success) {
          console.log("delete commitee completed")
          confirmDeleteDialog.value = true;
          fetchCommitees();
        }
      })
      .catch(error => {
        console.log(error)
        message.value = 'Erreur lors de la suppression de la commission.';
      });
};

const fetchCommitees = async () => {
    try {
      const response = await checkAuthentication();
      currentEmail.value = response.email;
    } catch (error) {
      console.log("Erreur lors de l'authentification:", error);
    }
    console.log("current user email",currentEmail.value)
    axios.get(urls.get_commitees, {
      params: {
        user_email: currentEmail.value
      }
    })
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            createdCommissions.value = response.data.commitees
            tempCommissions.value = response.data.temporaryCommitees
            console.log("temp commitee",tempCommissions.value)
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de la création de la récupération des data commission';
        });
};

const getPrependAvatarById = (id) => {
  const image = defaultImages.value.find(img => img.id === id);
  return image ? image.prependAvatar : null;
}

// Ajouter une commission permanente
const addCommission = async () => {
  if (commissionName.value && selectedImageCommiteeId.value) {
    try {
      const newCommission = {
        title: commissionName.value,
        prependAvatar: getPrependAvatarById(selectedImageCommiteeId.value),
        user_email: currentEmail.value
      };
      await axios.post(urls.create_commitee, newCommission)
          .then(response => {
            if (response.data.success) {
              console.log("commitee added",response.data.message)
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });


      createdCommissions.value.push({
        id: createdCommissions.value.length + 1,
        title: commissionName.value,
        prependAvatar: newCommission.image_avatar
      });
      commissionName.value = '';
      selectedImageCommiteeId.value = null;
      showDialog.value = false;
      message.value = 'Commission ajoutée avec succès.';
      isCommissionCreated.value = true;
    } catch (error) {
      message.value = 'Erreur lors de l\'ajout de la commission.';
      isCommissionCreated.value = false;
    }
  } else {
    message.value = 'Veuillez remplir tous les champs.';
    isCommissionCreated.value = false;
  }
};

// Ajouter une commission temporaire
const addTemporaryCommission = async () => {
  if (
      temporaryCommissionName.value &&
      startDate.value &&
      startTime.value &&
      endDate.value &&
      endTime.value &&
      selectedImageTemporaryId.value
  ) {
    if (new Date(`${startDate.value}T${startTime.value}`) >= new Date(`${endDate.value}T${endTime.value}`)) {
      errorDateMessage.value = 'La date de fin doit être après la date de début.';
      isErrorDateMessage.value = true;
      return;
    }

    try {
      const newTempCommission = {
        title: temporaryCommissionName.value,
        prependAvatar: getPrependAvatarById(selectedImageTemporaryId.value),
        user_email: currentEmail.value,
        startDate: startDate.value,
        startTime: startTime.value,
        endDate: endDate.value,
        endTime: endTime.value
      };
      await axios.post(urls.create_temporary_commitee, newTempCommission)
          .then(response => {
            if (response.data.success) {
              console.log("commitee temp added",response.data.message)
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });
      temporaryCommissionName.value = '';
      startDate.value = '';
      startTime.value = '';
      endDate.value = '';
      endTime.value = '';
      selectedImageTemporaryId.value = null;
      temporaryCommission.value = false;
      message.value = 'Commission temporaire ajoutée avec succès.';
      isTemporaryCommissionCreated.value = true;
      isErrorDateMessage.value = false;
    } catch (error) {
      errorDateMessage.value = 'Erreur lors de l\'ajout de la commission temporaire.';
      isErrorDateMessage.value = true;
    }
  } else {
    errorDateMessage.value = 'Veuillez remplir tous les champs.';
    isErrorDateMessage.value = true;
  }
};

// Validation des heures
const validateTimes = () => {
  if (new Date(`${startDate.value}T${startTime.value}`) >= new Date(`${endDate.value}T${endTime.value}`)) {
    errorDateMessage.value = 'La date de fin doit être après la date de début.';
    isErrorDateMessage.value = true;
  } else {
    isErrorDateMessage.value = false;
  }
};

const openEditTempCommiteeDialog = (item) => {
  editedTemporaryCommissionName.value = item.title;
  editedImageTemporaryId.value = item.prependAvatar;
  editedStartDate.value = getSplittedDate(item.startDate.date);
  editedStartTime.value = getSplittedTime(item.startDate.date);
  editedEndDate.value = getSplittedDate(item.endDate.date);
  editedEndTime.value = getSplittedTime(item.endDate.date);
  temporaryCommiteeId.value = item.id;

  showEditTemporaryDialog.value = true;
  console.log("edit temp commitee start date", editedStartDate.value  )
  console.log("edit temp commitee start time", editedStartTime.value  )
  console.log("edit temp commitee end date", editedEndDate.value  )
  console.log("edit temp commitee end time", editedEndTime.value  )
};

const openDeleteTempCommiteeDialog = (item) => {
  // Logic to handle delete confirmation dialog
  deleteTempCommitee.value = item;
  confirmDeleteTempCommiteeDialog.value = true;
};
</script>

<style scoped>

.custom-select .v-select__selection {
  display: flex;
  align-items: center;
}

.custom-list-item .custom-item-content {
  display: flex;
  align-items: center;
}

.custom-image-wrapper {
  margin-right: 8px;
}

.custom-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.custom-item-text {
  font-size: 14px;
}

.v-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-list-item-content {
  flex-grow: 1;
}
</style>
