const baseURL = 'https://backend.alhambradote.aimeroccitanie.fr';

const urls = {
    login: `${baseURL}/login`,
    register: `${baseURL}/register`,
    password_reset: `${baseURL}/request-password-reset`,
    verify_TOTP:`${baseURL}/verifyTOTP`,
    send_message:`${baseURL}/send-message`,
    create_commitee: `${baseURL}/create-commitee`,
    check_authentication:`${baseURL}/check_authentication`,
    refresh_token_request:`${baseURL}/token/refresh`,
    get_user_roles: `${baseURL}/user-roles`,
    get_users: `${baseURL}/api/users`,
    create_temporary_commitee: `${baseURL}/create-temporary-commitee`,
    get_commitees: `${baseURL}/commitees`,
    get_commitee_by_name: `${baseURL}/committeeByUsername`,
    add_notifs: `${baseURL}/add-notification`,
    add_user: `${baseURL}/add-user`,

    getApiDeleteCommitee: (commiteeID) => `${baseURL}/delete-commitee/${commiteeID}`,
    getApiUpdateCommitee: (commiteeID) => `${baseURL}/update-commitee/${commiteeID}`,
    getApiDeleteTemporaryCommitee: (tempCommiteeID) => `${baseURL}/delete-temporary-commitee/${tempCommiteeID}`,
    getApiUpdateTemporaryCommitee: (tempCommiteeID) => `${baseURL}/update-temporary-commitee/${tempCommiteeID}`,
    getApiUpdateUser: (userID) => `${baseURL}/api/user/edit/${userID}`,
    getApiDeleteUser: (userID) => `${baseURL}/api/user/delete/${userID}`,
    getApiResetPasswordConfirmation: (token) => `${baseURL}/reset-password/${token}`,
};

export default urls;
