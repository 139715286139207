<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-alert v-model="emailExists"
                 text="L'email est déjà utilisé. Utilisez une autre adresse mail"
                 title="Problème d'email"
                 type="error"
        ></v-alert>
        <div>
          <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
            <v-form @submit.prevent="register">
              <div class="text-subtitle-1 text-medium-emphasis">Prénom</div>
              <v-text-field v-model="name" :rules="nameRules" label="Prénom" placeholder="Entrez votre prénom" outlined required prepend-inner-icon="mdi-account" variant="outlined" dense></v-text-field>
              <div class="text-subtitle-1 text-medium-emphasis">Nom</div>
              <v-text-field v-model="lastname" :rules="lastNameRules" label="Nom" placeholder="Entrez votre nom" outlined required prepend-inner-icon="mdi-account-outline" variant="outlined" dense></v-text-field>
              <div class="text-subtitle-1 text-medium-emphasis">Email</div>
              <v-text-field v-model="email" :rules="emailRules" label="Email" placeholder="Entrez votre email" outlined required prepend-inner-icon="mdi-email" type="email" variant="outlined" dense></v-text-field>
              <div class="text-subtitle-1 text-medium-emphasis">Mot de passe</div>
              <v-text-field label="Mot de passe" v-model="password" :rules="passwordRules" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'" density="compact" placeholder="Entrez votre mot de passe" prepend-inner-icon="mdi-lock-outline" variant="outlined" @click:append-inner="visible = !visible"></v-text-field>
              <v-btn block class="mb-8" color="blue" :loading="isLoading"  size="large" variant="tonal" type="submit">
                S'inscrire
              </v-btn>
            </v-form>
            <v-card-text class="text-center">
              <a class="text-blue text-decoration-none" href="/login">Déjà un compte ? <v-icon icon="mdi-chevron-right"></v-icon></a>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import urls from "@/config/config";

export default {
  name: 'RegisterLogin',
  data() {
    return {
      visible: false,
      name: '',
      lastname: '',
      email: '',
      password: '',
      isLoading: false,
      emailExists: false,
      nameRules: [
        value => {
          if (value) return true
          return 'Entrez votre prénom'
        },
      ],
      lastNameRules: [
        value => {
          if (value) return true
          return 'Entrez votre nom de famille'
        },
      ],
      emailRules: [
        value => {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true

          return 'Entrez une adresse mail valide'
        },
      ],
      passwordRules: [
        value => {
          if (value) return true
          return 'Entrez votre mot de passe'
        },
      ],
    };
  },
  methods: {
    register() {

      axios.post(urls.register, {
        name: this.name,
        lastname: this.lastname,
        password: this.password,
        email: this.email,
        role: 'ROLE_NORMAL'
      })
          .then(response => {
            if (response.data.success) {
              this.isLoading = true;
              setTimeout(() => {
                this.emailExists = false;
                this.$router.push('/login');
              }, 200);
            }
          })
          .catch(error => {
            if (error.response.data.error_mail === "Email already in use") {
              this.emailExists = true;
            }
            this.isLoading = false
          });
    }
  }
};
</script>
