<template>
  <v-container fluid fill-height>
    <v-row justify="center">

      <!-- Commission event -->
      <v-dialog v-model="temporaryCommission" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTemporaryCommitee">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  v-model="temporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>
              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="selectedImageTemporaryId"
              class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
                  v-model="startDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="startTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>
              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="temporaryCommission = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Commission created -->
      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addCommitee">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  v-model="commissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>
              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="selectedImageCommiteeId"
                  class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image" />
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="message"
                  :type="isCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Existing code for displaying chat rooms and messages -->
    <v-row>
      <v-col cols="12" sm="3" v-if="!isMobile || !selectedChat">
        <v-card>
          <v-card-title class="text-center">Alhambradote Chat</v-card-title>
          <v-col class="text-center mb-4 d-flex justify-center">
            <v-menu offset-y activator="parent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="white" left>mdi-account-multiple-plus-outline</v-icon>
                  Choisir une commission
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="showDialog = true" class="py-1 text-center justify-center">
                  <v-list-item-title>Ajouter une commission</v-list-item-title>
                </v-list-item>
                <v-list-item @click="temporaryCommission = true" class="py-1 text-center justify-center">
                  <v-list-item-title>Ajouter une commission temporaire</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-alert
              v-if="showAlert"
              border="top"
              type="error"
              variant="outlined"
              prominent
              dismissible
              @input="showAlert = false"
          >
            Vous n'avez pas les droits nécessaires pour effectuer cette action.
          </v-alert>
          <v-card class="mx-auto bg-blue-darken-1" max-width="450">
            <v-card-title class="text-center">Commission créée</v-card-title>
            <v-list>
              <v-list-item v-for="item in createdCommissions" :key="item.id" @click="selectChat(item)">
                <div class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="ml-2">{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card class="mx-auto bg-blue-darken-1" max-width="450">
            <v-card-title class="text-center">Commission temporaire</v-card-title>
            <v-list>
              <v-list-item v-for="item in tempCommissions" :key="item.id" v-show="item.isOpen" @click="selectChat(item)">
                <div class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="ml-2">{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" v-if="!isMobile || selectedChat" class="d-flex flex-column" style="height: 100vh;">
        <v-card class="flex-grow-1 d-flex flex-column" style="height: 100%;">
          <v-card-title class="text-center">
            <v-btn v-if="isMobile" @click="deselectChat" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ selectedChatTitle }}
          </v-card-title>
          <v-list class="flex-grow-1" ref="messageList">
            <div v-for="(message, index) in currentMessages" :key="index" class="message-container" :class="{'message-sent': isMessageSentByCurrentUser(message), 'message-received': !isMessageSentByCurrentUser(message)}">
              <div class="message-content">
                <div class="message-name">{{ getName(message) }}</div>
                <div class="message-text">{{ message.contentMessage }}</div>
                <div class="message-date">{{ message.date }}</div>
              </div>
            </div>
          </v-list>
          <div>
            <v-row justify="end" style="padding: 16px;">
              <v-col>
                <v-text-field
                    v-model="newMessage"
                    label="Entrez votre message"
                    solo
                    clearable
                    style="width: 100%;"
                    @keydown.enter.prevent="sendMessage"
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="sendMessage" color="primary" height="56">Envoyer</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script setup>
import {ref, computed, nextTick, watch, onBeforeMount, onMounted} from 'vue';
import { checkAuthentication } from "@/LoginCheck";
import {displayedNotifications, notifications} from "@/components/notifications";
import axios from "axios";
import urls from "@/config/config";
const socket = ref(null);
const newMessage = ref('');
const createdCommissions = ref([]);
const defaultImages = ref([
  {id: 1, prependAvatar: '/img/animation.png', title: 'spectacle'},
  {id: 2, prependAvatar: '/img/communication.png', title: 'partage'},
  {id: 3, prependAvatar: '/img/finance.png', title: 'finance'},
  {id: 4, prependAvatar: '/img/restauration.png', title: 'restauration'},
  {id: 5, prependAvatar: '/img/global-communication.png', title: 'monde'},
]);
const tempCommissions = ref([]);
const selectedChat = ref(null);
const messageList = ref(null);
const isMobile = ref(false);
const messages = ref({});
const broadcastChannel = ref(new BroadcastChannel('chat'));
const selectedImageCommiteeId = ref(null);
const selectedImageTemporaryId = ref(null);
const currentUser = ref(null);
const currentEmail = ref(null);
const commissionName = ref('');
const temporaryCommissionName = ref('');
const temporaryImage = ref(null);
const isErrorDateMessage = ref(false);
const errorDateMessage = ref('');
const message = ref('');
const isCommissionCreated = ref(false);
const startDate = ref('');
const endDate = ref('');
const startTime = ref('');
const endTime = ref('');
const showDialog = ref(false);
const temporaryCommission = ref(false);
const showAlert=ref(false);
const isTemporaryCommissionCreated = ref(false);

const selectedChatTitle = computed(() => selectedChat.value ? selectedChat.value.title : '');
const currentMessages = computed(() => selectedChat.value ? messages.value[selectedChat.value.id] || [] : []);

const addCommitee = () => {
  const newCommission = {
    prependAvatar: getPrependAvatarById(selectedImageCommiteeId.value),
    title: commissionName.value,
    user_email:currentEmail.value
  };

  axios.post(urls.create_commitee, newCommission)
      .then(response => {
        if (response.data.success) {
          message.value = 'Commission créée avec succès';
          isCommissionCreated.value = true;
          commissionName.value = '';
          showDialog.value = false;
          message.value = null;
          getCommitees()
        } else {
          message.value = 'Erreur lors de la création de la commission';
        }
      })
      .catch(error => {
        console.error('Error:', error);
        message.value = 'Erreur lors de la création de la commission';
      });
};

const checkAllCommissions = () => {
  const now = new Date();
  tempCommissions.value.forEach(commission => {
    const eventDateTime = new Date(commission.eventDate.date);
    if (now > eventDateTime) {
      commission.isOpen = false;
    }
  });
  console.log("check commitee temp",tempCommissions.value)
};

const addTemporaryCommitee = () => {
  console.log("heure début", startTime.value)
  console.log("heure fin ", endTime.value)
  console.log("date début", startDate.value)
  console.log("date fin", endDate.value)
  if (!isErrorDateMessage.value) {
    const newTemporaryCommission = {
      prependAvatar: getPrependAvatarById(selectedImageTemporaryId.value),
      title: temporaryCommissionName.value,
      startDate: startDate.value,
      endDate: endDate.value,
      startTime: startTime.value,
      endTime: endTime.value,
      user_email:currentEmail.value
    };

    console.log("temp start date",startDate.value)

    axios.post(urls.create_temporary_commitee, newTemporaryCommission)
        .then(response => {
          if (response.data.success) {
            isTemporaryCommissionCreated.value = true;
            showDialog.value = false;
            temporaryImage.value = null;
            temporaryCommission.value = false;
            getCommitees()
          } else {
            message.value = 'Erreur lors de la création de la commission';
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de la création de la commission';
        });
  } else {
    this.message = '';
  }
};

const getPrependAvatarById = (id) => {
  const image = defaultImages.value.find(img => img.id === id);
  return image ? image.prependAvatar : null;
}

const selectChat = (chat) => {
  selectedChat.value = chat;
  nextTick(() => {
    scrollToBottom();
  });
};

const deselectChat = () => {
  selectedChat.value = null;
};

const checkIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

const requestNotificationPermission  = () => {
  if (Notification.permission === 'default') {
    Notification.requestPermission().then(permission => {
      if (permission !== 'granted') {
        console.log('Notification permission denied');
      }
    });
  }
};

const connectWebSocket = () => {
  socket.value = new WebSocket('wss://alhambradote.aimeroccitanie.fr:443');

  socket.value.onopen = () => {
    console.log('WebSocket connection established');
  };

  socket.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const channel = data.channel;
    const message = {
      title: data.message.contentMessage,
      date: data.message.date,
      user: data.message.user
    };

    addMessage(channel, message, false);
  };

  socket.value.onclose = () => {
    console.log('WebSocket connection closed');
  };

  socket.value.onerror = (error) => {
    console.error('WebSocket error: ', error);
  };
};

const addNotification = (bodyMessage, titleCommission, sender, sentDate) => {
  if (!localStorage.getItem("selectedNotifications").includes(titleCommission))
  {

    displayedNotifications.push({commission: titleCommission, senderName:sender, content:bodyMessage,date: sentDate})

    const exists = notifications.some(notification => notification.commission === titleCommission);

    if (!exists) {
      notifications.push({commission: titleCommission, senderName:sender, content:bodyMessage,date: sentDate,isDisabled:false});
      console.log('Notification ajoutée:', titleCommission);

      console.log("get commitee by name",currentEmail.value.email)
      axios.post(urls.get_commitee_by_name, {
        user_email: currentEmail.value.email,
      })
          .then(response => {
            if (response.data.success) {
              console.log(response.data)
              notifications.forEach(notification => {
                if ((notification.commission === response.data.commiteeName)
                && response.data.role === "ROLE_PRIVILEGE") {
                  console.log(notification.commission)
                  notification.isDisabled = true;
                }
              });
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });
    } else {
      console.log('Une notification avec cette commission existe déjà:',titleCommission);
    }

    console.log("sender notif",sender)
    axios.post(urls.add_notifs, {
      commission: titleCommission,
      senderName:sender,
      content:bodyMessage,
      date: sentDate,
      user_email:currentEmail.value,
    })
        .then(response => {
          if (response.data.success) {
            console.log("message registered into database",response.data.message)
          } else {
            message.value = 'Erreur lors de l\'envoi de message';
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de l\'envoi de message';
        });

  } else {
    console.log(`Le message de la commission ${titleCommission} n'a pas été ajouté car elle est filtrée.`);
  }
};

const sendMessage = () => {
  if (newMessage.value.trim() && selectedChat.value) {
    const messageData = {
      channelID: selectedChat.value.id,
      channel: selectedChat.value.title,
      message: {
        contentMessage: newMessage.value,
        user: currentUser.value,
        userEmail: currentEmail.value
      },
    };

    if (socket.value && socket.value.readyState === WebSocket.OPEN) {
      const messageWithTimestamp = {
        ...messageData,
        message: {
          ...messageData.message,
          date: new Date().toLocaleString()
        }
      };
      socket.value.send(JSON.stringify(messageWithTimestamp));

      console.log("user email send message",messageData.message.userEmail)
      console.log("user commitee message",messageData.channel)
      console.log("user content send message",messageData.message.contentMessage)
      console.log('Sending message...');
      axios.post(urls.send_message, {
        user_email: messageData.message.userEmail,
        commitee_name: messageData.channel,
        content: messageData.message.contentMessage,
        send_date: new Date().toLocaleString(),
      })
          .then(response => {
            console.log('Response received:', response); // Vérifier si la réponse est reçue
            if (response.data.success) {
              console.log("Message registered into database", response.data.message);
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error); // S'assurer que l'erreur est bien loguée
            message.value = 'Erreur lors de l\'envoi de message';
          });


      addMessage(messageData.channelID, messageWithTimestamp.message, true);
      addNotification(messageWithTimestamp.message.contentMessage,messageData.channel, messageWithTimestamp.message.user,messageWithTimestamp.message.date);
      showNotification(messageWithTimestamp.message.contentMessage, messageWithTimestamp.message.user,messageData.channel);
      newMessage.value = '';
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  }
};

const isMessageSentByCurrentUser = (message) => {
  return message.user === currentUser.value;
};

const getName = (message) => {
  return message.user;
};

const addMessage = (channel, message, shouldBroadcast) => {
  if (!messages.value[channel]) {
    messages.value[channel] = [];
  }
  messages.value[channel].push(message);

  localStorage.setItem('chatMessages', JSON.stringify(messages.value));

  if (shouldBroadcast) {
    const clonedMessage = {
      channel: channel,
      message: JSON.parse(JSON.stringify(message))
    };
    broadcastChannel.value.postMessage(clonedMessage);
  }

  nextTick(() => {
    scrollToBottom();
  });
};

const validateTimes = () => {
  if (startDate.value && endDate.value) {
    console.log("start date", startDate.value)
    console.log('end date', endDate.value)
    const start = new Date(startDate.value);
    const end = new Date(endDate.value);
    console.log("start time converted", start)
    console.log('end time converted', end)
    if (end <= start) {
      isErrorDateMessage.value = true;
      console.log("is error", isErrorDateMessage.value)
      errorDateMessage.value = 'L\'heure de fin doit être après l\'heure de début.';
    } else {
      console.log('date correct')
      isErrorDateMessage.value = false;
      errorDateMessage.value = '';
    }
  }
}

watch([startDate, endDate], validateTimes);


const showNotification = (title, user, commission) => {
  if (Notification.permission === 'granted') {
    new Notification("New Message", {
      body: `${commission} envoyé par ${user} au sujet de : ${title}`,
    });
  }
};

const loadMessages = () => {
  const storedMessages = localStorage.getItem('chatMessages');
  if (storedMessages) {
    messages.value = JSON.parse(storedMessages);
  }
};

const scrollToBottom = () => {
  if (messageList.value) {
    messageList.value.scrollTop = messageList.value.scrollHeight;
  }
};

const initializeBroadcastChannel = () => {
  broadcastChannel.value = new BroadcastChannel('chat');
  broadcastChannel.value.onmessage = (event) => {
    const {channel, message} = event.data;
    addMessage(channel, message, false);
  };
};

const checkUserAuthentication = async () => {
  const user = await checkAuthentication();
  currentUser.value = user.loginUser;
  currentEmail.value = user.email;

  if (user) {
    return {
      loginUser: currentUser.value,
      email: currentEmail.value
    }
  }

  return {
    loginUser: currentUser.value,
    email: currentEmail.value
  }
};

const getCommitees = () => {
  axios.get(urls.get_commitees, {
    params: {
      user_email: currentEmail.value
    }
  })
      .then(response => {
        console.log(response.data)
        if (response.data.success) {
          createdCommissions.value = response.data.commitees
          tempCommissions.value = response.data.temporaryCommitees
          tempCommissions.value.forEach(commission => {
              commission.isOpen = true;
          });
          console.log("all commitees",tempCommissions.value)
          checkAllCommissions();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        message.value = 'Erreur lors de la création de la récupération des data commission';
      });
}

const retrieveUserAuthData = () => {
  checkUserAuthentication()
      .then(authData => {
        currentUser.value = authData.loginUser;
        currentEmail.value = authData.email;
        console.log("retrieve function", authData)
        if (localStorage.getItem('pageRefreshed') === 'false') {
          localStorage.setItem('pageRefreshed', 'true');
          window.location.reload();
        }
        getCommitees();
      })
      .catch(error => {
        console.error('Error in checkUserAuthentication:', error);
      });
}

onMounted(() => {
  retrieveUserAuthData();
  requestNotificationPermission();
  checkIsMobile();
  connectWebSocket();
  loadMessages();
  initializeBroadcastChannel();
});
onBeforeMount(() => {
  if (socket.value) {
    socket.value.close();
  }
  window.removeEventListener('resize', checkIsMobile);
  if (broadcastChannel.value) {
    broadcastChannel.value.close();
  }
});
</script>

<style scoped>
.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message-sent {
  align-items: flex-end;
}

.message-received {
  align-items: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
}

.message-sent .message-content {
  background-color: whitesmoke;
  color:deepskyblue;
}

.message-name {
  font-weight: bold;
}

.message-text {
  margin-top: 5px;
}

.message-date {
  font-size: 0.8em;
  color: gray;
  text-align: right;
}
</style>