<template>
  <v-card>
    <!-- Toolbar remains unchanged -->
    <v-toolbar
        density="comfortable"
        :elevation="8"
        title="Panneau d'administrateur"
    ></v-toolbar>

    <div>
      <!-- Row to organize content based on screen size -->
      <v-row>
        <!-- Tabs displayed vertically on larger screens and horizontally on smaller ones -->
        <v-col :cols="12" :md="3">
          <v-tabs
              v-model="tab"
              color="primary"
              :direction="isMobile ? 'horizontal' : 'vertical'"
          >
            <v-tab prepend-icon="mdi-account" text="Utilisateurs" value="option-1"></v-tab>
            <v-tab prepend-icon="mdi-chat" text="Messages envoyées" value="option-2"></v-tab>
            <v-tab prepend-icon="mdi-account-group" text="Commissions" value="option-3"></v-tab>
          </v-tabs>
        </v-col>

        <!-- Tab content takes the full width on smaller screens -->
        <v-col :cols="12" :md="9">
          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="option-1">
              <v-card flat>
                <UserList />
              </v-card>
            </v-tabs-window-item>

            <v-tabs-window-item value="option-2">
              <v-card flat>
                <MessageList />
              </v-card>
            </v-tabs-window-item>

            <v-tabs-window-item value="option-3">
              <v-card flat>
                <CommiteeList />
              </v-card>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import UserList from "@/views/admin/users/UserList.vue";
import MessageList from "@/views/admin/messages/MessagesList.vue";
import { checkAuthentication } from "@/LoginCheck";
import CommiteeList from "@/views/admin/commitee/CommiteeList.vue";

export default {
  components: { CommiteeList, MessageList, UserList },
  data() {
    return {
      tab: 'option-1',
      currentUser: null,
      currentEmail: null,
      isMobile: false, // Track if it's mobile view
    };
  },
  mounted() {
    this.checkUserAuthentication();
    this.handleResize(); // Call on load
    window.addEventListener('resize', this.handleResize); // Listen for resize events
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize); // Clean up event listener
  },
  methods: {
    async checkUserAuthentication() {
      const hasRefreshed = localStorage.getItem('pageRefreshed') === 'true';
      if (!hasRefreshed) {
        console.log("refresh one time...");
        this.refreshOnce();
      } else {
        console.log("auth completed");
        const user = await checkAuthentication();
        this.currentUser = user;
        this.currentEmail = user.email;
      }
    },
    refreshOnce() {
      localStorage.setItem('pageRefreshed', 'true');
      window.location.reload();
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>
