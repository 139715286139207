<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-center mb-4 d-flex justify-center">
            Liste des Messages
          </v-card-title>
          <v-data-table
              :items="messages"
              item-key="id"
              class="elevation-1"
              loading-text="Chargement en cours..."
              :loading="isLoading"
              items-per-page-text="éléments par page"
              page-text=""
          >
            <!-- Slot pour la colonne creator -->
            <template v-slot:[`column.creator`]="{ item }">
              {{ item.creator.name }} <!-- Affiche seulement le nom du créateur -->
            </template>

            <!-- Slot pour la colonne commitee -->
            <template v-slot:[`column.commitee`]="{ item }">
              {{ item.commitee.name }} <!-- Affiche seulement le nom de la commission -->
            </template>

            <!-- Slot pour la colonne actions -->
            <template v-slot:[`column.actions`]="{ item }">
              <v-btn @click="editMessage(item.id)" color="orange" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="deleteMessage(item.id)" color="red" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import {checkAuthentication} from "@/LoginCheck";

export default {
  name: 'MessageList',
  data() {
    return {
      messages: [],
      isLoading:true,
      userEmail:'',
      headers: [
        { text: 'Content', value: 'content' },
        { text: 'Date', value: 'date' },
        { text: 'Creator', value: 'creator' },
        { text: 'Commitee', value: 'commitee' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    async fetchMessages() {
      try {
        try {
          const response = await checkAuthentication();
          this.isLoggedIn = true;
          this.userEmail = response.email;
        } catch (error) {
          console.log("Erreur lors de l'authentification:", error);
        }
        console.log("current user email",this.userEmail)
        const response = await axios.get('http://localhost:8000/api/messages',{
          params: {
            user_email: this.userEmail
          }
        });
        this.messages = response.data;
        if(response.data){
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    editMessage(id) {
      this.$router.push(`/edit-message/${id}`);
    },
    async deleteMessage(id) {
      try {
        await axios.delete(`/api/messages/${id}`);
        this.messages = this.messages.filter(message => message.id !== id);
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    },
  },
  mounted() {
    this.fetchMessages();
  },
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
