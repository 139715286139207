import { createApp } from 'vue';
import App from './components/NavBar.vue';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';
import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/auth/Login.vue';
import Register from './views/auth/Register.vue';
import PasswordReset from './views/auth/PasswordReset.vue';
import ResetPasswordConfirmation from './views/auth/ResetPasswordConfirmation.vue';
import Home from "@/views/Home.vue";
import ChatRoom from "@/views/chat/ChatRoom.vue";
import UserList from "@/views/admin/users/UserList.vue";
import MessagesList from "@/views/admin/messages/MessagesList.vue";
import CommiteeList from "@/views/admin/commitee/CommiteeList.vue";
import AdminDashboard from "@/views/admin/AdminDashboard.vue";
import { fr } from 'vuetify/locale'

const routes = [
    { path: '/login', name: 'Login',  meta : {title : "Connexion"}, component: Login },
    { path: '/register', name: 'Register', meta : {title : "Inscription"}, component: Register },
    { path: '/forgot-password', name: 'ForgotPassword', meta : {title : "Mot de passe oublié"}, component: PasswordReset },
    { path: '/', name: 'Home', meta : {title : "Accueil"},component: Home },
    { path: '/chat', name: 'ChattingRoom', meta : {title : "Discussion"},component: ChatRoom },
    { path: '/reset-password/:token', name: 'ResetPasswordConfirmation', meta : {title : "Mot de passe confirmation"}, component: ResetPasswordConfirmation, props: true},
    { path: '/users', name: 'UserList', meta : {title : "Liste des utilisateurs"},component: UserList },
    { path: '/messages', name: 'MessagesList', meta : {title : "Liste des messages"},component: MessagesList },
    { path: '/commitees', name: 'CommiteeList', meta : {title : "Liste des commissions"},component: CommiteeList },
    { path: '/admin/dashboard', name: 'AdministrationDashboard', meta : {title : "Espace administrateur"},component: AdminDashboard },
];

const vuetify = createVuetify({
    components,
    directives,
    locale: {
        locale: 'fr',
        messages: { fr },
    },
})

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp(App);

app.use(router);
app.use(vuetify);

app.mount('#app');

router.beforeEach((to, from, next) => {
    document.title = to.meta?.title;
    const faviconPath = '/alhambradote_favicon/favicon.ico';
    let link = document.querySelector("link[rel*='icon']");

    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.type = 'image/x-icon';
    link.href = faviconPath;

    const accessToken = localStorage.getItem('access');

    const restrictedNames = ['Login', 'Register', 'ForgotPassword','ResetPasswordConfirmation','Home'];

    if (!restrictedNames.includes(to.name) && !accessToken) {
        next({ name: 'Login' });
    } else {
        next();
    }
});
